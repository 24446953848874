header {
    &::after {
        content: "";
        background-image: linear-gradient(
            180deg,
            rgb(11, 11, 11) 0%,
            rgba(11, 11, 11, 0) 100%
        );
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) translateY(-50%);
        z-index: -1;
        backdrop-filter: blur(2px);
        pointer-events: none;
    }
}

.Toastify__toast-container {
    width: 30%;

    @media (max-width: 700px) {
        width: 90%;
    }
}

/*main {
    margin-top: 120px;
    overflow: hidden;
}*/
