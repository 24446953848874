.empower-hero-media {
    width: 100%;
    padding: 1.25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {}

    @media (min-width: 768px) {
        padding: 2rem 0;
    }

    @media (min-width: 992px) {}

    @media (min-width: 1200px) {}

    @media (min-width: 1400px) {}
}

.empower-hero {
    width: 100%;
    max-width: 1080px;
    aspect-ratio: 1643/728;
    margin: 0 auto;
    position: relative;
}

.empower-hero::after {
    content: "";
    background-color: transparent;
    background-blend-mode: normal;
    background-image: url(./../../assets/images/landing/home-elements.svg);
    background-size: 1643px 728px;
    background-position: calc(50% - 200px) 0;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all .5s ease-out;
    z-index: -1;
    will-change: opacity, clip-path, transform;
}

/* FEATURED-PRODUCERS */
.featured-producers {
    padding: 1rem 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {}

    @media (min-width: 768px) {
        padding: 2rem 0;
    }

    @media (min-width: 992px) {
        padding: 2rem 0 4rem;
    }

    @media (min-width: 1200px) {}

    @media (min-width: 1400px) {}

    & h5 {
        font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
        font-weight: 400;
        line-height: 1.313;
        color: var(--headline-grey);
    }

    & .producer-logo {
        max-width: 150px;
        opacity: 0.4;
        transition: all 0.3s ease-out;

        @media(hover: hover) {
            &:hover {
                opacity: 1;
                transition: all 0.3s ease-out;
            }
        }
    }
}


.testimonial-items {
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


/* CONSUMER-TESTIMONIALS */
.consumer-testimonials {
    padding: 1.75rem 0;

    @media (min-width: 576px) {}

    @media (min-width: 768px) {
        padding: 2rem 0;
    }

    @media (min-width: 992px) {
        padding: 3.75rem 0;
    }

    @media (min-width: 1200px) {}

    @media (min-width: 1400px) {}

    & h3 {
        font-size: clamp(1.25rem, 1.0417rem + 1.1111vw, 1.875rem);
        color: var(--vt-c-white);
        mix-blend-mode: screen;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 1.125rem;

        &>img {
            mix-blend-mode: screen;
        }
    }

    & .testimonials {
        --swiper-scrollbar-border-radius: 10px;
        --swiper-scrollbar-top: auto;
        --swiper-scrollbar-bottom: 0.25rem;
        --swiper-scrollbar-left: auto;
        --swiper-scrollbar-right: 4px;
        --swiper-scrollbar-sides-offset: 17px;
        --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
        --swiper-scrollbar-drag-bg-color: rgba(255, 255, 255, 0.6);
        --swiper-scrollbar-size: 4px;

        --swiper-pagination-color: var(--color-primary);
        --swiper-pagination-left: auto;
        --swiper-pagination-right: 8px;
        --swiper-pagination-bottom: -0.25rem;
        --swiper-pagination-top: auto;
        --swiper-pagination-fraction-color: inherit;
        --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
        --swiper-pagination-progressbar-size: 4px;
        --swiper-pagination-bullet-size: 8px;
        --swiper-pagination-bullet-width: 8px;
        --swiper-pagination-bullet-height: 8px;
        --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 0.6);
        --swiper-pagination-bullet-inactive-opacity: 0.2;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-horizontal-gap: 4px;
        --swiper-pagination-bullet-vertical-gap: 6px;

        padding: 0 0 1.5rem 0;

        &::before,
        &::after {
            content: '';
            height: 100%;
            width: 17px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            z-index: 5;
        }

        &::before {
            background: linear-gradient(90deg, #0B0B0B, #0B0B0B00);
            left: 0;
        }

        &::after {
            background: linear-gradient(90deg, #0B0B0B00, #0B0B0B);
            right: 0;
        }

        & .swiper-wrapper {
            z-index: 2;
        }

        & .swiper-slide {
            width: auto;
        }
    }
}

/* TESTIMONIAL-CARD */
.testimonial-card {
    width: 20rem;
    min-width: 18.75rem;
    //align-self: stretch;
    scroll-snap-align: start;
    user-select: none;

    & .image {
        width: 100%;
        max-width: 56px;
        height: auto;
    }

    & .img-thumbnail {
        padding: 0 0 0 0;
        border: 0 none;
    }

    & .quote {
        font-size: clamp(0.813rem, 0.7923rem + 0.1102vw, 0.875rem);
        line-height: 24px;
        color: var(--vt-c-white);
    }

    & .company {
        font-size: 0.625rem;
        font-weight: 600;
        color: var(--color-primary);
    }

    & .name {
        font-size: 0.813rem;
        font-weight: 600;
        color: var(--vt-c-white);
    }

    & .position {
        font-size: 0.813rem;
        font-weight: 600;
    }

    & .card-body {
        padding-top: 1.75rem;
        padding-right: 1.75rem;
        padding-left: 1.75rem;
    }
}

/* PLATFORM-POINTS */
.platform-points {
    padding: 3.75rem 0;

    @media (min-width: 576px) {}

    @media (min-width: 768px) {
        padding: 4rem 0;
    }

    @media (min-width: 992px) {
        padding: 6.75rem 0;
    }

    @media (min-width: 1200px) {}

    @media (min-width: 1400px) {}

    & h1 {
        font-size: clamp(2rem, 1.3333rem + 3.5556vw, 4rem);
        text-align: center;
        line-height: 40px;
        widows: 100%;
        max-width: 940px;
        margin: 0 auto;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) {
            line-height: 52px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 768px) {
            line-height: 62px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 992px) {
            line-height: 72px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1200px) {}
    }

    & .points-list {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 2rem;
        justify-content: center;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) {
            grid-template-columns: repeat(auto-fill, minmax(250px, 328px));
            transition: all 0.3s ease-out;
        }

        @media (min-width: 768px) {}

        @media (min-width: 992px) {
            grid-gap: 4rem;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1200px) {}

        @media (min-width: 1400px) {}
    }
}

.point-card {
    width: 100%;
    min-width: 16.25rem;
    max-width: 20.5rem;
    height: 100%;
    margin: 0 auto;
    scroll-snap-align: start;
    user-select: none;

    & .icon {
        max-width: 100%;
        height: auto;
    }

    & .card-body {
        padding: 1.625rem 1.625rem 2.25rem 1.625rem;
    }

    & .icon {
        width: 100%;
        height: 110px;
        object-fit: contain;
    }

    & .card-title {
        font-size: clamp(1.125rem, 1.0000rem + 0.6667vw, 1.5rem);
        color: var(--vt-c-white);
    }

    & .card-text {
        font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
    }
}