.page-hero {
    min-height: 260px;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;

    @media (min-width: 576px) {}

    @media (min-width: 768px) {}

    @media (min-width: 992px) {
        min-height: 390px;
        padding: 2rem;
        transition: all 0.3s ease-out;
    }

    @media (min-width: 1200px) {}

    & .meta {
        width: 100%;
        max-width: 870px;
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        z-index: 5;

        @media (min-width: 576px) {}

        @media (min-width: 768px) {}

        @media (min-width: 992px) {}

        @media (min-width: 1200px) {}
    }

    & h1 {
        font-size: clamp(2rem, 1.8333rem + 0.8889vw, 2.5rem);
        font-weight: 700;
        line-height: clamp(2.724rem, 2.6653rem + 0.3129vw, 2.9rem) !important;
        text-align: center;
        color: var(--vt-c-white);
        width: 100%;
        max-width: 960px;
        margin: 0 0 0 0;
        padding: 0 0 0.375rem 0;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) {
            line-height: 52px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 768px) {
            line-height: 62px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 992px) {
            line-height: 72px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1200px) {
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1400px) {
            transition: all 0.3s ease-out;
        }
    }

    & h3 {
        font-size: clamp(1rem, 0.9583rem + 0.2222vw, 1.125rem);
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--vt-c-text-med);
        width: 100%;
        margin: 0 auto;
        padding: 0 0 0.375rem 0;
    }

    & .services-hero-media {
        width: 100%;
        height: 100%;
        padding: 1.25rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        @media (min-width: 576px) {
            // SM

        }

        @media (min-width: 768px) {
            // MD
            padding: 2rem 0;
        }

        @media (min-width: 992px) {
            // LG

        }

        @media (min-width: 1200px) {
            // XL

        }

        @media (min-width: 1400px) {
            // XXL

        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
        }

        &::before {
            background-image: url('./../../assets/images/services/bg-0.png');
            width: 1061px;
            height: 628px;
            top: -116px;
            left: 0;
        }

        &::after {
            background-image: url('./../../assets/images/services/bg-1.png');
            width: 1336px;
            height: 659px;
            top: -116px;
            left: 260px;
        }
    }

    & .services-hero {
        width: 100%;
        max-width: 1080px;
        position: relative;

        & svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0 0 0 0;

            @media (min-width: 576px) {
                // SM

            }

            @media (min-width: 768px) {
                // MD

            }

            @media (min-width: 992px) {
                // LG
                margin: 0 0 6.25rem 0;
            }

            @media (min-width: 1200px) {
                // XL

            }

            @media (min-width: 1400px) {
                // XXL

            }
        }

        &.ready {

            &::after {
                opacity: 1;
                transition: all 1s ease-out;
            }
        }
    }
}


.about-container {
    width: 100%;
    max-width: 1112px;
    margin-top: 200px !important;
    transition: all 0.3s ease-out;

    @media (min-width: 576px) {}

    @media (min-width: 768px) {
        margin-top: 300px !important;
        transition: all 0.3s ease-out;
    }

    @media (min-width: 992px) {}

    @media (min-width: 1200px) {}

    @media (min-width: 1400px) {}

    & .intro-copy {
        columns: 1;
        column-gap: 2.813rem;

        @media (min-width: 576px) {}

        @media (min-width: 768px) {}

        @media (min-width: 992px) {
            columns: 2;
            column-gap: 2.813rem;
        }

        @media (min-width: 1200px) {}

        @media (min-width: 1400px) {}

        & p {
            font-size: clamp(1rem, 0.9583rem + 0.2222vw, 1.125rem);
        }
    }

    & .cemboo-service-items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        @media (min-width: 576px) {}

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
        }

        @media (min-width: 992px) {}

        @media (min-width: 1200px) {}

        @media (min-width: 1400px) {}
    }

    & .services-solve {
        width: 100%;
        max-width: 430px;

        & h3 {}

        & h2 {
            font-size: 2rem;
            font-weight: 700;
            color: var(--vt-c-white);
            margin-bottom: 1.125rem;
        }

        & p {
            font-size: clamp(0.875rem, 0.7917rem + 0.4444vw, 1.125rem);
            font-weight: 400;
        }
    }

    & .cemboo-service-item {

        & .icon {
            margin-bottom: 0.625rem;
        }

        & h3 {
            font-size: clamp(1rem, 0.8333rem + 0.8889vw, 1.5rem);
            font-weight: 700;
            line-height: 1;
            color: var(--vt-c-white);
            margin-bottom: 1.125rem;
        }

        & p {
            font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
            font-weight: 400;
        }
    }
}