.cemboo-roadmap {
    padding: 2rem 0;

    & .intro {
        max-width: 650px;

        & p {
            font-size: clamp(1rem, 0.9583rem + 0.2222vw, 1.125rem);
        }
    }

    & .timeline-intro {
        padding: 12rem 0;
    }

    & .timeline-list {
        max-width: 720px;
        display: flex;
        flex-flow: column nowrap;
        //gap: 6.250rem;
        position: relative;
        z-index: 1;
    }

    & .timeline-list--item {
        padding-bottom: 6.250rem;
        gap: 1.5rem;
        z-index: 9999;

        &.current-item {

            & .marker {

                & .marker--icon {

                    &::before {
                        background-image: url('./../../assets/images/roadmap/marker-active.png');
                        background-size: 24px 24px;

                        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
                            background-image: url('./../../assets/images/roadmap/marker-active_2x.png');
                            background-size: 24px 24px;
                        }
                    }
                }
            }
        }

        & .date {
            color: var(--vt-c-text-med);
            font-size: clamp(1.125rem, 1.0000rem + 0.6667vw, 1.5rem);
            font-weight: 700;
            white-space: nowrap;
            display: inline-block;
        }

        & .description {
            color: var(--text-alt-white);
            font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
            font-weight: 400;
        }

        & .marker {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            top: 0;
            left: 0;

            & .marker--icon {
                //background-color: var(--vt-c-white);
                width: 24px;
                height: 24px;
                margin: 7px;
                display: block;
                position: relative;
                transition: all 0.3s ease-out;

                &::before {
                    content: '';
                    background-image: url('./../../assets/images/roadmap/marker.png');
                    background-size: 14px 14px;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.3s ease-out;

                    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
                        background-image: url('./../../assets/images/roadmap/marker_2x.png');
                        background-size: 14px 14px;
                    }
                }

                &.seen {
                    background-color: var(--color-primary);
                    transition: all 0.3s ease-out;

                    &::before {
                        opacity: 1;
                        transition: all 0.3s ease-out;
                    }
                }
            }
        }
    }

    & .roadmap-list {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        display: grid;
        grid-template-columns: repeat(2, 175px);
        grid-gap: 1rem;

        & li {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;
        }

        & .label {
            font-size: 1rem;
            font-weight: 600;
            color: var(--vt-c-white);
        }
    }


    & h1 {
        font-size: clamp(2rem, 1.3333rem + 3.5556vw, 4rem);
        text-align: center;
        line-height: 40px;
        widows: 100%;
        max-width: 940px;
        margin: 0 auto;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) {
            // SM
            line-height: 52px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 768px) {
            // MD
            line-height: 62px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 992px) {
            // LG
            line-height: 72px;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1200px) {
            // XL

        }
    }
}