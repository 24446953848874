.cmb-confirm-dialog {
    background-color: rgba(11, 11, 11, 1);
    text-align: center;
    color: rgba(255, 255, 255, 1);
    width: 100%;
    max-width: 31.25rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 1.25rem 4.688rem rgba(0, 0, 0, 0.23);
}

.cmb-confirm-dialog--inner {
    padding: 2.5rem;
}

.cmb-confirm-dialog--inner h1 {
    font-size: clamp(1.125rem, 0.9643rem + 0.7143vw, 1.5rem);
    font-weight: 600;
    margin: 0 0 0.75rem 0;
}

.cmb-confirm-dialog--inner p {
    font-size: clamp(0.875rem, 0.8214rem + 0.2381vw, 1rem);
    margin: 0 0 1.75rem 0;
}

.cmb-confirm-dialog--inner strong {
    font-weight: 600;
}

.cmb-confirm-dialog--controls {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
}

.cmb-confirm-dialog--btn {
    color: rgba(255, 255, 255, 0.6);
    background: none;
    width: 100%;
    max-width: 10rem;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease-out;
}

.cmb-confirm-dialog--btn.yes {
    color: var(--color-primary-75);
    border: 1px solid var(--color-primary-75);
}

.cmb-confirm-dialog--btn.yes-danger {
    color: var(--bs-danger);
    border: 1px solid var(--bs-danger);
}

@media (hover: hover) {
    .cmb-confirm-dialog--btn:hover {
        color: rgba(255, 255, 255, 0.88);
        border: 1px solid rgba(255, 255, 255, 0.6);
        cursor: pointer;
        transition: all 0.3s ease-out;
    }

    .cmb-confirm-dialog--btn.yes:hover {
        color: var(--color-primary);
        border: var(--color-primary);
        transition: all 0.3s ease-out;
    }
}

.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.6) !important;
    backdrop-filter: blur(24px) !important;
    transition: all 0.3s ease-out;
}
