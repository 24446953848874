#footer {
    font-size: 0.875rem;
}

.footer-upper {
    padding: 60px 0 30px 0;
}

.footer-lower {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}


/* FOOTER-NEWSLETTER */
.footer-newsletter {
    text-align: center;
    font-size: 15px;
    padding: 50px 0;
    display: none;

    & h4 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        margin: 0 0 20px 0;
        padding: 0;
    }

    & form {
        text-align: left;
        margin-top: 30px;
        padding: 6px 10px;
        border-radius: 50px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
        position: relative;

        & input[type=email] {
            width: calc(100% - 100px);
            padding: 4px 8px;
            border: 0;
        }

        & input[type=submit] {
            background: var(--color-primary);
            font-size: 16px;
            color: #000000;
            padding: 0 20px;
            border: 0;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            transition: all 0.3s ease-out;


            &:hover {
                background: var(--vt-c-white);
            }
        }
    }
}


/* FOOTER-MISSION */
.footer-mission {
    margin-bottom: 30px;

    & h3 {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        margin: 0 0 10px 0;
        padding: 2px 0 2px 0;
    }

    & h4 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 12px;
        position: relative;
    }

    & p {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 20px;
        color: var(--vt-c-white);
        margin-bottom: 0;
    }
}


/* FOOTER-LINKS */
.footer-links,
.footer-highlights {
    margin-bottom: 30px;

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;

        & li {
            padding: 0.438rem 0;
            display: flex;
            align-items: center;
            gap: 0.625rem;

            &:first-child {
                padding-top: 0;
            }
        }

        & i {
            font-size: 1.125rem;
            line-height: 1;
            color: var(--color-primary);
            position: relative;
            pointer-events: none;

            &::before {
                opacity: 0;
                position: relative;
                left: -1.125rem;
                transition: left 0.3s ease-out, opacity 0.3s ease-out;
            }
        }

        & a {
            color: var(--vt-c-white);
            line-height: 1;
            padding: 0 0 0 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: 0.375rem;
            position: relative;
            transition: all 0.3s ease-out;

            &:hover {
                text-decoration: none;
                color: var(--color-primary);
                transition: all 0.3s ease-out;

                & i {

                    &::before {
                        opacity: 1;
                        left: 0;
                        transition: left 0.3s ease-out, opacity 0.3s ease-out;
                    }
                }

                & svg {
                    opacity: 1;
                    left: 0;
                    transition: all 0.3s ease-out;

                    & path {
                        fill: var(--color-primary);
                        transition: all 0.3s ease-out;
                    }
                }
            }

            & svg {
                opacity: 0;
                position: relative;
                left: -1.125rem;
                pointer-events: none;
                transition: all 0.3s ease-out;

                & path {
                    fill: var(--vt-c-white);
                    transition: all 0.3s ease-out;
                }
            }
        }
    }

    &--list {

        & li {

            &>img {
                margin: 0 0 0 0;
            }

            & span {
                font-size: clamp(0.75rem, 0.7290rem + 0.1120vw, 0.813rem);
                font-weight: 400;
                color: var(--vt-c-white);

                & strong {
                    font-weight: 700;
                }
            }
        }
    }

    & .footer-links--list {

        & a {
            font-size: clamp(0.75rem, 0.7290rem + 0.1120vw, 0.813rem);
            font-weight: 600;

            & span {
                color: inherit;
            }

            &.active {
                color: var(--color-primary);
            }
        }
    }
}

/* COPYRIGHT */
.copyright {
    font-size: 0.75rem;

    @media (max-width: 768px) {
        text-align: center;
    }
}

/* SOCIAL-LINKS */
.social-links {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 1.125rem;

    & a {
        font-size: 1.125rem;
        color: var(--vt-c-white);
        padding: 0.25rem;

        @media(hover: hover) {
            &:hover {
                color: var(--color-primary);

                & i {

                    &::before {
                        transform: translateY(-4px);
                        transition: all 0.3s ease-out;
                    }
                }
            }
        }

        & i {

            &::before {
                transition: all 0.3s ease-out;
            }
        }
    }
}