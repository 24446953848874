.pricing {

    & .page-hero {
        min-height: 200px;
        padding: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) {}

        @media (min-width: 768px) {}

        @media (min-width: 992px) {
            min-height: 200px;
            padding: 2rem;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1200px) {}
    }
}

.advantages-container {

    & section {
        width: 100%;
        max-width: 1112px;
        margin: 0 auto;
        padding: 60px 0;
        transition: all 0.3s ease-out;
    }

    & .section-description {
        max-width: 890px;
    }

    & .advantage-items {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0 1rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 2rem;
        justify-content: center;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) {
            grid-template-columns: 1fr 1fr;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 768px) {
            padding: 0 0 0 0;
            grid-template-columns: repeat(auto-fill, minmax(8.75rem, 17.25rem));
            grid-gap: 3rem 3rem;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 992px) {
            grid-gap: 3rem 3rem;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1200px) {
            grid-gap: 3rem 6.75rem;
            transition: all 0.3s ease-out;
        }

        @media (min-width: 1400px) {
            grid-gap: 4rem 8.75rem;
        }
    }

    & .advantage-item {

        & h3 {
            font-size: clamp(1.125rem, 1.0000rem + 0.6667vw, 1.5rem);
            color: var(--vt-c-white);
        }

        & p {
            font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
        }
    }
}


/* PLATFORM-PACKAGES */
.platform-packages {
    width: 100%;
    max-width: 1112px;
    margin: 0 auto;
    padding: 60px 0;

    & .package-card {
        background-color: var(--transparent);
        width: 100%;
        max-width: 312px;

        //&:nth-of-type(odd) {
        &.popular {
            background-color: var(--card-bg);
            background: linear-gradient(var(--card-bg), var(--card-bg)) padding-box, linear-gradient(180deg, #B7F84D 0%, rgba(183, 248, 77, 0) 100%) border-box;
            border-radius: 0.5rem;
            border: 1px solid transparent;
        }

        &:not(.popular) {
            border-width: 1px;
            border-style: dotted;
            border-color: grey;
            border-image-source: linear-gradient(165.72deg, rgba(255, 255, 255, 0) -3.66%, rgba(255, 255, 255, 0.5) 41.67%, rgba(255, 255, 255, 0) 108.07%);
            border-image-slice: 1;

            @media (min-width: 576px) {}

            @media (min-width: 768px) {
                border: none;
            }

            @media (min-width: 992px) {}

            @media (min-width: 1200px) {}

            @media (min-width: 1400px) {}
        }

        & .card-body {
            padding: 4rem 2.25rem 3rem 2.25rem;
        }

        & .card-title {
            font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
            font-weight: 700;
            color: var(--vt-c-white);
        }

        & .card-text {
            font-size: 1rem;
        }

        & .price {
            font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
            font-weight: 700;
            color: var(--color-primary);

            & span {
                font-size: clamp(1rem, 0.8333rem + 0.8889vw, 1.5rem);
                font-weight: 400;
                opacity: 0.7;
            }
        }

        & .notes {
            list-style-type: square;
            margin: 0 0 0 1.125rem;
            padding: 0 0 0 0;

            &>li {
                font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
                font-weight: 400;
                color: var(--radcheck-border-color);
                margin: 0.5rem 0;
            }
        }

        & .card-footer {
            background-color: transparent;
            padding: 2.0rem 3.125rem;
        }
    }

    & .popular-choice {
        background: linear-gradient(270.46deg, #B7F84D 0%, #FFFFFF 133.76%);
        border-radius: 8px 8px 0px 0px;
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 5;
        transform: translateX(-50%);

        & span {
            font-size: 0.875rem;
            font-weight: 600;
            color: var(--slate-dark);
        }
    }
}



.contact-cta {
    min-height: 463px;
    overflow: visible;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        width: 1212px;
        min-height: 463px;
        background-image: url('./../../assets/images/contact-bg.png');
        background-position: 50% 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & .meta {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        & h2 {
            font-size: clamp(2rem, 1.8333rem + 0.8889vw, 2.5rem);
            font-weight: 700;
        }
    }
}