/* Reset styles */

:root {
  --font-size-sm: clamp(1rem, 0vw + 1rem, 1rem);
  --font-size-md: clamp(1.25rem, 0.19vw + 1.21rem, 1.33rem);
  --font-size-lg: clamp(1.56rem, 0.49vw + 1.47rem, 1.78rem);
  --font-size-xl: clamp(1.95rem, 0.95vw + 1.78rem, 2.37rem);
  --font-size-xxl: clamp(2.44rem, 1.64vw + 2.13rem, 3.16rem);
  --font-size-xxxl: clamp(3.05rem, 2.64vw + 2.56rem, 4.21rem);

  --black: rgba(0, 0, 0, 1);

  --white: rgba(255, 255, 255, 1);
  --white-0: rgba(255, 255, 255, 0);
  --white-05: rgba(255, 255, 255, 0.05);
  --white-20: rgba(255, 255, 255, 0.2);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-60: rgba(255, 255, 255, 0.6);

  --text-alt-white: rgba(230, 227, 236, 1);

  --transparent: rgba(11, 11, 11, 0);

  --headline-grey: rgba(159, 159, 159, 1);

  --color-primary: #b7f84d;
  --color-primary-75: rgba(184, 249, 79, 0.75);
  --color-primary-50: rgba(184, 249, 79, 0.5);
  --color-primary-25: rgba(184, 249, 79, 0.25);
  --color-primary-15: rgba(184, 249, 79, 0.15);

  --bs-primary-rgb: 184, 249, 79;
  --color-secondary: rgba(255, 255, 255, 1);

  --slate: rgba(60, 66, 86, 1);
  --slate-dark: rgba(11, 11, 11, 1);

  --gradient-primary: linear-gradient(
    103.07deg,
    rgba(184, 249, 79, 0.2) -38.19%,
    rgba(141, 198, 63, 0.2) 102.21%
  );
  --gradient-primary-hover: linear-gradient(
    103.07deg,
    rgba(184, 249, 79, 0.4) -38.19%,
    rgba(141, 198, 63, 0.4) 102.21%
  );
  --gradient-white-primary: linear-gradient(
    270.35deg,
    var(--color-primary) 0.39%,
    var(--color-secondary) 102.68%
  );

  --gradient-white-fade: linear-gradient(
      to right,
      var(--white-0),
      var(--white-50),
      var(--white-0)
    )
    1;
  --gradient-dark-fade-down-blocker: linear-gradient(
    180deg,
    var(--slate-dark) 0%,
    var(--transparent) 100%
  );
  --gradient-dark-fade-up-blocker: linear-gradient(
    to top,
    var(--slate-dark),
    var(--transparent)
  );

  --card-bg: rgba(18, 20, 25, 1);

  --radcheck-border-color: rgba(228, 255, 219, 1);

  --link-shadow: 0 0 16px var(--white-60);
  --button-shadow: 0 0 20px var(--color-primary-15);

  --vt-c-white: #ffffff;
  --vt-c-white-soft: #f8f8f8;
  --vt-c-white-mute: #f2f2f2;
  --vt-c-white-60: rgba(255, 255, 255, 0.6);

  --vt-c-black: #0b0b0b;
  --vt-c-black-soft: #222222;
  --vt-c-black-mute: #282828;

  --vt-c-indigo: #2c3e50;

  --vt-c-divider-light-1: rgba(60, 60, 60, 0.29);
  --vt-c-divider-light-2: rgba(60, 60, 60, 0.12);
  --vt-c-divider-dark-1: rgba(84, 84, 84, 0.65);
  --vt-c-divider-dark-2: rgba(84, 84, 84, 0.48);

  --vt-c-text-light-1: var(--vt-c-indigo);
  --vt-c-text-light-2: rgba(60, 60, 60, 0.66);
  --vt-c-text-dark-1: var(--vt-c-white);
  --vt-c-text-dark-2: rgba(235, 235, 235, 0.64);

  --vt-c-text-med: rgba(209, 214, 224, 1.0);
}

/* semantic color variables for this project */
:root {
  --color-background: var(--vt-c-white);
  --color-background-soft: var(--vt-c-white-soft);
  --color-background-mute: var(--vt-c-white-mute);

  --color-border: var(--vt-c-divider-light-2);
  --color-border-hover: var(--vt-c-divider-light-1);

  --color-heading: var(--vt-c-text-light-1);
  --color-text: var(--vt-c-text-light-1);

  --section-gap: 160px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: var(--vt-c-black);
    --color-background-soft: var(--vt-c-black-soft);
    --color-background-mute: var(--vt-c-black-mute);

    --color-border: var(--vt-c-divider-dark-2);
    --color-border-hover: var(--vt-c-divider-dark-1);

    --color-heading: var(--vt-c-text-dark-1);
    --color-text: var(--vt-c-text-dark-2);
  }
}

/* BOOTSTRAP-CUSTOM */
$font-family-sans-serif: "Open Sans", sans-serif;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$headings-font-weight: 700;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$body-color: rgba(209, 214, 224, 1.0);
$body-bg: rgba(11, 11, 11, 1);

$primary: rgba(183, 248, 77, 1);
$secondary: rgba(18, 20, 25, 1);

$btn-border-radius: 0.063rem;
$btn-border-radius-sm: 0.063rem;
$btn-border-radius-lg: 0.063rem;

@import "bootstrap/scss/bootstrap.scss";

.container,
.container-sm,
.container-md,
.container-lg {
  transition: all 0.3s ease-out;
}

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.625rem;
}

.btn-primary {
  background: var(--gradient-primary);
  font-size: 0.875rem;
  color: var(--color-primary);
  border: 1px solid rgba(141, 198, 63, 0.2);
  border-radius: 1px;
  box-shadow: 0 0 0 var(--transparent);
  font-weight: 600;
  position: relative;
  transition: all 0.3s ease-out;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid rgba(141, 198, 63, 0);
    border-radius: 1px;
    opacity: 0;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      background: var(--gradient-primary);
      color: var(--color-primary);
      border: 1px solid rgba(141, 198, 63, 0.2);
      border-radius: 1px;
      box-shadow: var(--button-shadow);
      transition: all 0.3s ease-out;

      &::after {
        border: 1px solid rgba(141, 198, 63, 0.4);
        opacity: 1;
        transition: all 0.3s ease-out;
      }
    }
  }

  &:active,
  &:focus {
    background-color: var(--transparent) !important;
    background: var(--gradient-primary);
    color: var(--color-primary) !important;
    border: 1px solid rgba(141, 198, 63, 0.2) !important;
    border-radius: 1px;
    box-shadow: var(--button-shadow);
    transition: all 0.3s ease-out;

    &::after {
      border: 1px solid rgba(141, 198, 63, 0.4);
      opacity: 1;
      transition: all 0.3s ease-out;
    }
  }
}

.card {
  background-color: rgba(18, 20, 25, 1);
}

/**/

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

ul,
li,
p,
div,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

li {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 49.37%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 2px;
  margin: 1rem 0;
  border: 0;
}

body {
  background: #0b0b0b;
  background-image: url("./assets/images/ellipse_glow.png"), url("./assets/images/bg-pattern.svg"),
    url("./assets/images/bg-pattern.svg");
  background-position: right top, left 50% top -800px, left 50% bottom -800px;
  background-repeat: no-repeat, repeat-x, repeat-x;
  background-attachment: fixed, scroll, scroll;
  /*font-family: "Poppins", sans-serif;*/
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: var(--vt-c-text-med);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  margin: 0;

  &::after {
    content: "";
    background-image: linear-gradient(
      to top,
      rgb(11, 11, 11),
      rgba(11, 11, 11, 0)
    );
    width: 100%;
    height: 56px;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    pointer-events: none;
  }

  /* ::SCROLLBAR */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(141, 198, 63, 0.5);
    outline: 1px solid slategrey;
  }
}

/* GRADIENT-TEXT */
.gradient-text {
  /* Fallback: Set a background color. */
  background-color: var(--slate-dark);

  /* Create the gradient. */
  background-image: var(--gradient-white-primary);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

/* BTN-SUCCESS */
.btn-success {
  background: linear-gradient(
      103.07deg,
      rgba(183, 248, 77, 0.2) -38.19%,
      rgba(141, 198, 63, 0.2) 102.21%
    ),
    #0b0b0b;
  border-color: rgba(141, 198, 63, 0.5);
}

.btn-success:hover,
.btn-success:active,
.btn-success:checked {
  background: linear-gradient(
    103.07deg,
    rgba(183, 248, 77, 0.2) -38.19%,
    rgba(141, 198, 63, 0.2) 102.21%
  );
  border-color: rgba(141, 198, 63, 0.5);
}

/* FORMS/INPUT */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
}

// Verify Email Modal styles

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.verify-account-modal-container {
  margin: 0 auto;
  margin-bottom: 20px;

  &.character--selected {
    position: relative;
  }

  &.character--selected:empty::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }
}


// Verify Email Modal styles

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.verify-account-modal-container {
  margin: 0 auto;
  margin-bottom: 20px;

  &.character--selected {
    position: relative;
  }

  &.character--selected:empty::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }
}


/* Alerts Overlay z index */
.react-confirm-alert-overlay {
    z-index: 2000 !important;
}
