.cemboo-blockchain {
    padding: 2rem 0;

    &::before {
        content: '';
        background-image: url('./../../assets/images/block/bc-bg.svg');
        background-position: calc(50% + 2rem) 0;
        background-repeat: no-repeat;
        width: 2275px;
        height: 737px;
        position: absolute;
        top: -88px;
        left: 50%;
        transform: translateX(-50%);
    }

    & p {
        font-size: clamp(1rem, 0.9583rem + 0.2222vw, 1.125rem);
        color: var(--vt-c-white);
    }

}

.media-callout {
    width: 100%;
    max-width: 1112px;
    margin: 0 auto;
    padding: 120px 0;
    overflow: hidden;

    & .media-items {
        display: flex;
        flex-flow: column nowrap;
        gap: 4rem 3rem;

        @media (min-width: 576px) {
            // SM

        }

        @media (min-width: 768px) {
            // MD

        }

        @media (min-width: 992px) {
            // LG
            gap: 8rem 3rem;
        }

        @media (min-width: 1200px) {
            // XL

        }

        @media (min-width: 1400px) {
            // XXL

        }
    }

    & .media-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 3rem;

        &:nth-of-type(even) {
            & .image {
                order: 2;
            }

            & .meta {
                order: 1;
            }
        }


        & .image {
            width: 100%;
            order: 1;

            @media (min-width: 576px) {
                // SM

            }

            @media (min-width: 768px) {
                // MD

            }

            @media (min-width: 992px) {
                // LG
                width: 50%;
            }

            @media (min-width: 1200px) {
                // XL

            }

            @media (min-width: 1400px) {
                // XXL

            }
        }

        & .meta {
            width: 100%;
            order: 2;

            @media (min-width: 576px) {
                // SM

            }

            @media (min-width: 768px) {
                // MD

            }

            @media (min-width: 992px) {
                // LG
                width: 50%;
            }

            @media (min-width: 1200px) {
                // XL

            }

            @media (min-width: 1400px) {
                // XXL

            }

            & h4 {
                font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
                font-weight: 400;
                text-transform: uppercase;
            }

            & h3 {
                font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
                color: var(--vt-c-white);
            }

            & p {
                font-size: clamp(1rem, 0.9583rem + 0.2222vw, 1.125rem);
                font-weight: 400;
                color: var(--vt-c-white);
            }
        }
    }
}


.latest-ecosystem-news {
    padding: 120px 0;
    //overflow: hidden;
    position: relative;

    & .section-header {
        margin-bottom: 1rem;

        & h3 {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            text-transform: uppercase;
            margin-bottom: 0;
            display: inline-block;
        }
    }

    & .latest-news-items {
        width: 100vw;
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    & .latest-news-item {
        width: 17.5rem;
        min-width: 17.5rem;
        display: flex;
        user-select: none;

        &.card {
            background-color: rgb(18, 20, 25);
        }

        & .card-body--link {
            display: block;
            position: relative;
        }

        & .card-body {
            padding: 0.750rem 1rem;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 1rem;
        }

        & .icon {


            & img {
                width: 52px;
                min-width: 52px;
                height: 52px;
                object-fit: cover;
                overflow: hidden;
                border-radius: 8px;
            }
        }

        & .meta {

            & .date {
                font-size: 0.625rem;
                text-transform: uppercase;
                margin-bottom: 0.25rem;
            }

            & h4 {
                font-size: 0.875rem;
                font-weight: 400;
                color: var(--vt-c-white);
                margin-bottom: 0;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: -webkit-box;
            }
        }
    }
}

.blockchain-feed {
    --swiper-scrollbar-border-radius: 10px;
    --swiper-scrollbar-top: auto;
    --swiper-scrollbar-bottom: 0.25rem;
    --swiper-scrollbar-left: auto;
    --swiper-scrollbar-right: 4px;
    --swiper-scrollbar-sides-offset: 17px;
    --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(255, 255, 255, 0.6);
    --swiper-scrollbar-size: 4px;

    --swiper-pagination-color: var(--color-primary);
    --swiper-pagination-left: auto;
    --swiper-pagination-right: 8px;
    --swiper-pagination-bottom: -0.25rem;
    --swiper-pagination-top: auto;
    --swiper-pagination-fraction-color: inherit;
    --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
    --swiper-pagination-progressbar-size: 4px;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 0.6);
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;


    padding-bottom: 1.25rem;

    &::before,
    &::after {
        content: '';
        height: 100%;
        width: 17px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        z-index: 5;
    }

    &::before {
        background: linear-gradient(90deg, #0B0B0B, #0B0B0B00);
        left: 0;
    }

    &::after {
        background: linear-gradient(90deg, #0B0B0B00, #0B0B0B);
        right: 0;
    }

    & .swiper-wrapper {
        z-index: 2;
    }

    & .swiper-slide {
        width: auto;
    }
}


.contact-cta {
    min-height: 463px;
    overflow: visible;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        width: 1212px;
        min-height: 463px;
        background-image: url('./../../assets/images/contact-bg.png');
        background-position: 50% 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & .meta {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        & h2 {
            font-size: clamp(2rem, 1.8333rem + 0.8889vw, 2.5rem);
            font-weight: 700;
        }
    }
}