.team-container {
    width: 100%;
    max-width: 1112px;

    & h3 {
        line-height: 1.313;
    }

    & .intro-copy {
        columns: 1;
        column-gap: 2.813rem;

        @media (min-width: 576px) {
            // SM

        }

        @media (min-width: 768px) {
            // MD

        }

        @media (min-width: 992px) {
            // LG
            columns: 2;
            column-gap: 3rem;
        }

        @media (min-width: 1200px) {
            // XL

        }

        @media (min-width: 1400px) {
            // XXL

        }

        & p {
            font-size: clamp(1rem, 0.9583rem + 0.2222vw, 1.125rem);
        }
    }

}

#team .page-hero {
    position: relative;

    &::before {
        content: "";
        background-image: url(./../../assets/images/team/teab-hero-bg.svg);
        background-position: calc(50% + 2rem) 0;
        background-repeat: no-repeat;
        width: 2275px;
        height: 737px;
        position: absolute;
        top: 20px;
        left: calc(50% + 10rem);
        z-index: -1;
        transform: translateX(-50%);

        @media (min-width: 576px) {}

        @media (min-width: 768px) {
            top: -45px;
        }

        @media (min-width: 992px) {}

        @media (min-width: 1200px) {}

        @media (min-width: 1400px) {}
    }
}

.stats-image {
    width: 100%;
    max-width: 394px;
    margin: 0 0 0 0.625rem;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (min-width: 576px) {
        // SM

    }

    @media (min-width: 768px) {
        // MD

    }

    @media (min-width: 992px) {
        // LG
        grid-template-columns: repeat(3, 1fr);
        gap: 4.563rem;
    }

    @media (min-width: 1200px) {
        // XL

    }

    @media (min-width: 1400px) {
        // XXL

    }

    &>div {

        &:nth-of-type(1) {
            grid-column: span 2;
        }
    }
}

.member-card {
    background-color: var(--transparent);

    & .card-body {
        padding-top: 2rem;
        padding-left: 0;
        padding-right: 0;
    }

    & .meta {
        position: relative;

        &::before {
            content: '';
            background-color: var(--color-primary);
            width: 32px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
        }

        & h5 {
            font-size: clamp(0.625rem, 0.3333rem + 1.5556vw, 1.5rem);
            font-weight: 700;
            color: var(--vt-c-white);
            padding-top: 1.5rem;
        }

        & h6 {
            font-size: clamp(0.625rem, 0.3333rem + 1.5556vw, 1.5rem);
            font-weight: 400;
            color: var(--headline-grey);
        }
    }


}