.contact-form {
    padding: 2rem 0;

    & h3 {
        font-size: clamp(1.25rem, 1.0000rem + 1.3333vw, 2rem);
        line-height: 1.313;
        color: var(--vt-c-white);
    }

    & p {
        font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
    }

    & .form-check-label {
        font-size: 0.875rem;
        padding-left: 0.5rem;
    }

    & .color-white {
        color: var(--vt-c-white);
    }

    .form-control {
        background-color: var(--white-05);
        border-color: var(--transparent);
        border-radius: 0;

        &:focus {
            box-shadow: 0 0 0.25rem 0.25rem rgba(255, 255, 255, 0.15);
            border-color: var(--color-primary);
        }

        @media(hover: hover) {
            &:hover {
                border-color: #fff3;
            }
        }
    }

    .form-select {
        background-color: var(--white-05);
        border-color: var(--transparent);
        border-radius: 0;

        &:focus {
            box-shadow: 0 0 0.25rem 0.25rem rgba(255, 255, 255, 0.15);
            border-color: var(--color-primary);
        }

        & option {

            &:disabled {
                background-color: #0b0b0b;
            }

            &:is(:checked) {
                background-color: rgba(11, 11, 11, 0.95);
                padding: 0.5rem 0.25rem;
            }

            &:not(:checked) {
                background-color: #0b0b0b;
                padding: 0.5rem 0.25rem;
            }
        }
    }

    .form-check-input {
        background-color: var(--slate-dark);
        border-color: var(--radcheck-border-color);
        width: 1.25rem;
        height: 1.25rem;
        /*margin-right: 0.75rem;*/
        transition: all 0.3s ease-out;

        &[type=checkbox] {
            border-radius: 50%;
        }

        &:checked {
            background-color: var(--slate-dark);
            transition: all 0.3s ease-out;
        }
    }

    .recaptcha-disclaimer {
        color: var(--white-60);
        font-size: 0.75rem;
    }

    // hide recaptcha badge
    .grecaptcha-badge {
        visibility: hidden;
    }
}